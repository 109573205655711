<template>
    <div class="square">
        <h5 class="article--title">{{ title }}</h5>
        <p class="article--description">{{ description }}</p>
        <button class="btn border slim" @click="link()"><span class="icon-right submit">Submit</span></button>
    </div>
</template>

<script>
export default {
    name: 'submit-card',
    components: {},
    data: () => {
        return {}
    },
    props: {
        title: String,
        description: String,
        goto: String
    },
    computed: {
        endpoint() {
            return process.env.VUE_APP_REDIRECT
        }
    },
    methods: {
        link() {
            if(this.title=='Events')
                window.open('https://qt.eu/create-an-event/')
            else if(this.$store.getters.getLogged)
                window.open(process.env.VUE_APP_REDIRECT + this.goto, '_blank');
            else
                this.$router.push('/login')
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
</style>