<template>
  <div class="page-layout get-involved" v-if="pageData && pageData.attributes">
    
    <HeaderIntroImage central :headerMetadata="pageData">
      <div class="action-links">
        <router-link to="" @click.native="smoothScroll('be-part-of-community')" class="plain link icon-right arrow downward">
          <h4>Be part of QTEdu community</h4>
        </router-link>
        <router-link to="" @click.native="smoothScroll('submit-your-contribution')" class="plain link icon-right arrow downward">
          <h4>Submit your contribution</h4>
        </router-link>
        <router-link to="" @click.native="smoothScroll('collaborate')" class="plain link icon-right arrow downward">
          <h4>Collaborate with us</h4>
        </router-link>
    </div>
    </HeaderIntroImage>
    
    <BlockWithImage id="be-part-of-community" v-if="pageData.field_ref_page_content" :imageurl="block1">
      <h2 class="blue">{{ this.pageData.field_ref_page_content[0].attributes.field_titolo.value }}</h2>
      <div class="list-1" v-html="this.pageData.field_ref_page_content[0].attributes.field_text_with_summary.value"></div>
      <button @click="goto()" class="btn slim"><span>Join</span></button>
    </BlockWithImage>

    <div id="submit-your-contribution" class="fullwidth dark-bg" v-if="pageData.field_ref_page_content">
      <div class="container submit-container">
        <div class="intro">
          <h2>{{ this.pageData.field_ref_page_content[1].attributes.field_titolo.value }}</h2>
          <div class="page-body blue-title" 
            v-html="this.pageData.field_ref_page_content[1].attributes.field_text_with_summary.value"></div>
        </div>
        <div class="tile-grid">
          <SubmitCard title="Study Programs, Courses and Trainings" description="" goto="node/add/program" />
          <SubmitCard title="Material" description="" goto="node/add/material" />
          <SubmitCard title="Evaluation Tools" description="" goto="node/add/evaluation" />

          <SubmitCardContact title="Internship Opportunities" description="" goto="" />
          <SubmitCard title="Events" description="" goto="https://qt.eu/create-an-event/" />
          <SubmitCardContact title="School Curricula" description="" goto="" />
        </div>
      </div>
    </div>

    <BlockWithImage id="collaborate" mirror v-if="pageData.field_ref_page_content" :imageurl="block2">
      <h2 class="blue">{{ this.pageData.field_ref_page_content[2].attributes.field_titolo.value }}</h2>
      <div class="list-1" v-html="this.pageData.field_ref_page_content[2].attributes.field_text_with_summary.value"></div>
      <!-- <button @click="goto()" class="btn slim"><span>Apply</span></button> -->
    </BlockWithImage>

  </div>
</template>

<script>
import SubmitCard from '../components/layout-blocks/SubmitCard.vue'
import SubmitCardContact from '../components/layout-blocks/SubmitCardContact.vue'

import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import BlockWithImage from '../components/layout-blocks/BlockWithImage.vue'
//import MemberCard from '../components/layout-blocks/MemberCard.vue'
import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'about',
  components: {
    HeaderIntroImage,
    BlockWithImage,
    SubmitCard,
    SubmitCardContact
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Get Involved",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: "dbb24328-4fb5-4b33-858f-ad955c5ac2e2"
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    block1() {
      return require('../assets/block_img_1.jpg')
    },
    block2() {
      return require('../assets/block_img_4.jpg')
    },
  },
  methods: {
    smoothScroll(s) {
      var offset = window.innerWidth >= 1024 ? 100 : 60
      window.scrollTo({
        top: document.getElementById(s).getBoundingClientRect().top - offset,
        behavior: 'smooth'
      })
    },
    goto() {
      this.$router.push('/login')
    }
  },
  mounted() {
    fetchSinglePage(this.currentPageID, {
      include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
    })
    .then(res => {
      this.pageData = res
      //console.log(this.pageData)
    })
  },
  watch: {}
}
</script>

<style lang="scss">
.action-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .link {
    color: #fff;
    width: 90%;
    text-align: left;
    margin: 1em auto;
    position: relative;
    border-top: 2px rgba(#fff, .5) dashed;
    &::before { top: 1.5rem; }
    &:hover { 
      text-decoration: underline; 
      &:before {
        top: 3em;
      }
    }
    h4 { width: 90%; }
  }
}

.fullwidth {
  padding: 3em 0;
}

@media (min-width: 768px) {
  .action-links {
    flex-direction: row;
    justify-content: center;
    .link {
      width: 33.33%;
      margin: .5em;
      h4 { width: 80%; }
    }
  }
}

@media (min-width: 992px) {
  .action-links {
    flex-direction: row;
    justify-content: center;
    .link {
      width: 25%;
      max-width: 300px;
      margin: 1em 2em;
    }
  }
}


</style>