<template>
    <div class="square">
        <h5 class="article--title">{{ title }}</h5>
        <p class="article--description">{{ description }}</p>
        <a class="btn border plain slim" @click="link()"><span class="icon-right submit">Contact us</span></a>
        <!-- href="mailto:info@qtedu.com" -->
    </div>
</template>

<script>
export default {
    name: 'submit-card',
    components: {},
    data: () => {
        return {}
    },
    props: {
        title: String,
        description: String,
        goto: String
    },
    computed: { },
    methods: {
        link() {
            window.location.href = "mailto:info@qtedu.com";
        }
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
</style>